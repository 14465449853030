<template>
  <div class="check-vehicle" ref="checkVehicle">
    <div class="form-area" ref="form">
      <analysis-form
        @getFormVal="onSubmit"
        :showUserName="form.type === 3"
        :showCar="form.type != 4 && form.type != 3"
        :showCompany="form.type == 4"
      >
        <el-button
          type="primary"
          size="small"
          v-if="form.type === 1"
          :disabled="carArr.length === 0"
          :loading="downLoadStatus"
          @click="exportData(1)"
          >导出</el-button
        >
        <el-button
          type="primary"
          size="small"
          v-if="form.type === 2"
          :disabled="carCountArr.length === 0"
          :loading="downLoadStatus"
          @click="exportData(2)"
          >导出</el-button
        >
        <el-button
          type="primary"
          size="small"
          v-if="form.type === 3"
          :loading="downLoadStatus"
          :disabled="userCount.length === 0"
          @click="exportData(3)"
          >导出</el-button
        >
        <el-button
          type="primary"
          size="small"
          v-if="form.type === 4"
          :disabled="companyArr.length === 0"
          :loading="downLoadStatus"
          @click="exportData(4)"
          >导出</el-button
        >
      </analysis-form>
      <el-radio-group v-model="form.type" @change="changeState">
        <el-radio :label="v.lable" v-for="(v, i) in options" :key="i">{{
          v.value
        }}</el-radio>
      </el-radio-group>
    </div>
    <!-- 车辆明细 -->
    <carDetailTable
      v-if="form.type === 1"
      ref="pagination1"
      :total="total"
      :height="tableHeight"
      :tableData="carArr"
      :modelObj="modelObj"
      :typeObj="typeObj"
      @sendInfo="getPageInfo"
    />
    <!-- 车辆统计 -->
    <car-table
      v-if="form.type === 2"
      ref="pagination2"
      :tableData="carCountArr"
      :height="tableHeight"
      :total="total"
      :modelObj="modelObj"
      :typeObj="typeObj"
      @sendInfo="getPageInfo"
    />

    <!-- 用户统计 -->
    <user-detial
      v-if="form.type === 3"
      :tableData="userCount"
      :height="tableHeight"
      ref="pagination3"
      :total="total"
      @sendInfo="getPageInfo"
    />
    <!--企业统计 -->
    <company-table
      v-if="form.type === 4"
      ref="pagination4"
      :tableData="companyArr"
      :height="tableHeight"
      :total="total"
      @sendInfo="getPageInfo"
    />
  </div>
</template>

<script>
import analysisForm from '@/components/analysisForm/analysisForm.vue'
import carDetailTable from './components/carDetail.vue'
import companyTable from './components/companyTable.vue'
import carTable from './components/carTable.vue'
import userDetial from './components/userDetial.vue'

import {
  querySpotCheckDetail,
  querySpotCheckGroupVehicle,
  querySpotCheckGroupCompany,
  querySpotCheckGroupUser
} from '@/api/lib/gps-api.js'
import { queryDictsByCodes } from '@/api/lib/api.js'

import { formatDict, getCurent, formatDate } from '@/common/utils/index'
import { addExcelExport } from '@/api/lib/refreshAlarm.js'

export default {
  name: 'checkVehicle',
  components: {
    analysisForm,
    carDetailTable,
    companyTable,
    carTable,
    userDetial
  },
  data() {
    return {
      tableHeight: 0,
      tableData: [],
      total: 0,

      options: [
        // <!-- 1：查询车辆明细  2：车辆统计  3：用户统计  4：企业统计-->
        {
          lable: 4,
          value: '企业统计表',
          queryPath:'/manageStatisticsExport/querySpotCheckCompanyExport'
        },
        {
          lable: 2,
          value: '车辆统计表',
          queryPath:'/manageStatisticsExport/querySpotCheckVehicleExport'
        },
        {
          lable: 1,
          value: '车辆明细表',
          queryPath:'/manageStatisticsExport/querySpotCheckDetailExport'
        },
        {
          lable: 3,
          value: '用户统计表',
          queryPath:'/manageStatisticsExport/querySpotCheckUserExport'
        }
      ],

      form: {
        vehicleNos: [],
        companyId: null,
        beginTime: null,
        endTime: null,
        currentPage: 1,
        pageSize: 10,
        type: 1
      },
      exportForm: {}, //导出需要的数据
      //企业
      companyArr: [],
      userCount: [],
      carArr: [],
      //车辆统计
      carCountArr: [],
      typeObj: null,
      modelObj: null,
      exportForm: {},
      downLoadStatus: false
    }
  },
  methods: {
    // 计算表格高度
    computeHeight() {
      const wholeHeight = this.$refs.checkVehicle.clientHeight
      const formHeight = this.$refs.form.clientHeight
      this.tableHeight = wholeHeight - formHeight - 30 - 10 - 24
    },
    //导出
    exportData(key) {
      this.downLoadStatus = true
      delete this.exportForm.currentPage
      delete this.exportForm.pageSize
      let item = this.options.find((res) => res.lable === key)
      let data = {
        baseUrl: 'report',
        userId: sessionStorage.getItem('userId'),
        createTime: formatDate(new Date()),
        fileName: `车辆抽查${item.value}-${getCurent()}`,
        filePath: null,
        fileStatus: 1,
        generateTime: null,
        queryParam: JSON.stringify(this.exportForm),
        queryPath: item.queryPath
      }
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg)
          this.downLoadStatus = false
          this.$store.dispatch('setRefreshStatus', true)
        })
        .catch(() => {
          this.downLoadStatus = false
        })
    },
    /**请求类型切换 */
    changeState() {
      this.reset()
    },
    /**请求参数清空*/
    reset() {
      if (this.$refs.pagination1) this.$refs.pagination1.reset()
      if (this.$refs.pagination2) this.$refs.pagination2.reset()
      if (this.$refs.pagination3) this.$refs.pagination3.reset()
      if (this.$refs.pagination4) this.$refs.pagination4.reset()
      this.form.currentPage = 1
      this.form.pageSize = 10
      this.total = 0
      this.carArr = []
      this.carCountArr = []
      this.userCount = []
      this.companyArr = []
    },
    /**点击查询 */
    onSubmit(v) {
      this.reset()
      this.form = { ...this.form, ...v }
      this.form.currentPage = 1

      this.getDataList()
    },
    /**查询 */
    getDataList() {
      let data = { ...this.form }
      let self = this
      switch (this.form.type) {
        case 1:
          /** 车辆明细 */
          delete data.companyId
          querySpotCheckDetail(data).then((res) => {
            if (res.code === 1000) {
              this.carArr = res.data.list
              this.total = res.data.total
            } else {
              this.carArr = []
              this.total = 0
            }
          })
          break
        case 2:
          /**车辆统计 */
          delete data.companyId
          querySpotCheckGroupVehicle(data).then((res) => {
            if (res.code === 1000) {
              this.carCountArr = res.data.list
              this.total = res.data.total
            } else {
              this.carCountArr = []
              this.total = 0
            }
          })

          break
        case 3:
          /* 用户统计*/
          delete data.companyId
          delete data.vehicleNos
          querySpotCheckGroupUser(data).then((res) => {
            if (res.code === 1000) {
              this.userCount = res.data.list
              this.total = res.data.total
            } else {
              this.userCount = []
              this.total = 0
            }
          })

          break
        case 4:
          /**企业统计 */
          delete data.vehicleNos
          querySpotCheckGroupCompany(data).then((res) => {
            if (res.code === 1000) {
              this.companyArr = res.data.list
              this.total = res.data.total
            } else {
              this.companyArr = []
              this.total = 0
            }
          })

          break

        default:
          break
      }
      this.exportForm = { ...data }
    },
    /**分页参数切换 */
    getPageInfo(params) {
      this.form.currentPage = params.currentPage
      this.form.pageSize = params.pageSize
      if (
        this.carArr.length === 0 &&
        this.carCountArr.length === 0 &&
        this.userCount.length === 0 &&
        this.companyArr.length === 0
      )
        return
      this.getDataList()
    },
    //获取字典值
    getDicts() {
      queryDictsByCodes({ parentCodes: 'VETY,CPYS' }).then((res) => {
        if (res.code === 1000) {
          this.modelObj = formatDict(res.data.VETY)
          this.typeObj = formatDict(res.data.CPYS)
        }
      })
    }
  },
  created() {
    this.getDicts()
  },
  mounted() {
    this.$nextTick(() => {
      this.computeHeight()
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  destroyed() {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>

<style lang="scss" scoped>
.check-vehicle {
  height: 100%;
  width: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.theme-project-gps {
  .check-vehicle {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
