<template>
  <!-- 车辆明细 -->
  <div class="checkVehDetail">
    <el-table :data="tableData" style="width: 100%" :height="height">
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column
        prop="cph"
        label="车牌号"
        :width="100 * $store.state.vehicle.screenCoefficient"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        label="车牌颜色"
        :width="80 * $store.state.vehicle.screenCoefficient"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          {{ typeObj[Number(scope.row.licensePlateColor)] }}
        </template>
      </el-table-column>
      <el-table-column
        label="车辆类型"
        :width="130 * $store.state.vehicle.screenCoefficient"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          {{ modelObj[Number(scope.row.vehicleType)] }}
        </template>
      </el-table-column>
      <el-table-column
        prop="companyName"
        label="所属道路运输企业"
        :width="240 * this.$store.state.vehicle.screenCoefficient"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="gpsTime"
        label="定位时间"
        :formatter="formatNull"
        :width="170 * $store.state.vehicle.screenCoefficient"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="velocity"
        label="速度"
        :formatter="formatNull"
      ></el-table-column>
      <el-table-column prop="limitSpeed" label="限速"></el-table-column>
      <el-table-column
        prop="position"
        label="位置"
        :width="220 * $store.state.vehicle.screenCoefficient"
        :show-overflow-tooltip="true"
        :formatter="formatNull"
      ></el-table-column>
      <el-table-column
        prop="spotName"
        label="抽查内容"
        :width="140 * $store.state.vehicle.screenCoefficient"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="userName"
        label="抽查人"
        :width="80 * $store.state.vehicle.screenCoefficient"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="抽查时间"
        :width="170 * $store.state.vehicle.screenCoefficient"
        :show-overflow-tooltip="true"
      ></el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="pageObj.pageSize"
        :current-page="pageObj.currentPage"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: Number
    },
    tableData: {
      type: Array
    },
    total: {
      type: Number,
      default: 0
    },
    modelObj: {
      type: Object,
      default: () => {
        return {}
      }
    },
    typeObj: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      pageObj: {
        currentPage: 1,
        pageSize: 10
      }
    }
  },
  methods: {
    /**数据为空默认显示- */
    formatNull(row, column, cellValue) {
      return cellValue == null || cellValue == '' ? '-' : cellValue
    },
    /**查询时,分页参数还原 */
    reset() {
      this.pageObj.pageSize = 10
      this.pageObj.currentPage = 1
    },
    /** 分页参数切换*/
    handleSizeChange(val) {
      this.pageObj.pageSize = val
      this.pageObj.currentPage = 1
      this.$emit('sendInfo', this.pageObj)
    },
    /**分页参数切换 */
    handleCurrentChange(val) {
      this.pageObj.currentPage = val
      this.$emit('sendInfo', this.pageObj)
    }
  }
}
</script>

