<template>
  <div>
    <el-table :data="tableData" style="width: 100%" :height="height">
      <el-table-column type="index" width="70" label="序号"></el-table-column>
      <el-table-column
        prop="cph"
        label="车牌号"
        :width="100 * this.$store.state.vehicle.screenCoefficient"
      ></el-table-column>
      <el-table-column
        label="车牌颜色"
        :width="80 * $store.state.vehicle.screenCoefficient"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          {{ typeObj[Number(scope.row.licensePlateColor)] }}
        </template>
      </el-table-column>
      <el-table-column
        label="车辆类型"
        :width="130 * $store.state.vehicle.screenCoefficient"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          {{ modelObj[Number(scope.row.vehicleType)] }}
        </template>
      </el-table-column>
      <el-table-column
        prop="companyName"
        label="所属道路运输企业"
        :width="220 * this.$store.state.vehicle.screenCoefficient"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column label="抽查内容(次数)">
        <el-table-column
          label="查看定位信息"
          prop="localCount"
          :width="120 * this.$store.state.vehicle.screenCoefficient"
        ></el-table-column>
        <el-table-column
          label="查看历史轨迹"
          prop="travelCount"
          :width="120 * this.$store.state.vehicle.screenCoefficient"
        ></el-table-column>
        <el-table-column label="查看视频" prop="videoCount"> </el-table-column>
        <el-table-column label="违章处理" prop="illegalCount"></el-table-column>
      </el-table-column>
      <el-table-column prop="allCount" label="合计"></el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="pageObj.pageSize"
        :current-page="pageObj.currentPage"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: Number
    },
    tableData: {
      type: Array
    },
    total: {
      type: Number,
      default: 0
    },
    modelObj: {
      type: Object,
      default: () => {
        return {}
      }
    },
    typeObj: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      pageObj: {
        currentPage: 1,
        pageSize: 10
      }
    }
  },
  methods: {
    /**查询时,分页参数还原 */
    reset() {
      this.pageObj.pageSize = 10
      this.pageObj.currentPage = 1
    },
    /** 分页参数切换*/
    handleSizeChange(val) {
      this.pageObj.pageSize = val
      this.pageObj.currentPage = 1
      this.$emit('sendInfo', this.pageObj)
    },
    /**分页参数切换 */
    handleCurrentChange(val) {
      this.pageObj.currentPage = val
      this.$emit('sendInfo', this.pageObj)
    }
  }
}
</script>

<style>
</style>
